<template>
  <BaseModal :show="show" @close="$emit('close')">
    <h3 slot="header" class="font-bold text-base">Agregar administrador</h3>

    <div class="space-y-6">
      <!-- Admin name -->
      <div>
        <BaseInput
          label="Nombre"
          placeholder="Nombre"
          :error="errors.first('name')"
          v-model="name"
        />
      </div>

      <!-- Admin email -->
      <div>
        <BaseInput
          label="Correo"
          placeholder="Correo"
          :error="errors.first('email')"
          v-model="email"
        />
      </div>
    </div>

    <div slot="footer" class="mt-6 flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleAddAdmin"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >Agregar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
export default {
  name: 'AdminCreateModal',
  components: { BaseButton, BaseModal, BaseInput },
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      name: '',
      email: '',
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    async handleAddAdmin() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.email);
      this.loading = true;
      this.errors.clear();

      try {
        const admin = await this.$store.dispatch('admin/createAdmin', formData);
        this.clearForm();
        this.$emit('close');
        this.$emit('created', admin);
        this.$store.dispatch('notification/addSuccess', 'El administrador se ha creado correctamente');
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.name = '';
      this.email = '';
    },
  },
};
</script>
