<template>
  <div class="w-full">
    <template v-if="admins.length">
      <table class="min-w-full">
        <thead>
          <tr>
            <th scope="col" class="px-6 py-3 text-left">Nombre de usuario</th>
            <th scope="col" class="px-6 py-3 text-left">Correo</th>
            <th scope="col" class="px-6 py-3 text-left">Estatus</th>
            <th scope="col" class="px-6 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(admin, i) in admins" :key="i" :class="{ 'bg-primary-100': i % 2 === 0 }">
            <td scope="col" class="px-6 py-3 text-left">{{ admin.name }}</td>
            <td scope="col" class="px-6 py-3 text-left">{{ admin.email }}</td>
            <td scope="col" class="px-6 py-3 text-left">
              <BaseSwitch
                :value="admin.active"
                @input="handleSwitchInput"
                @on="handleSwitchOn(admin)"
                @off="handleSwitchOff(admin)"
              />
            </td>
            <td scope="col" class="px-6 py-3 text-right space-x-4">
              <button
                type="button"
                class="rounded-full focus:outline-none focus:ring"
                @click="handlerEdit(admin)"
              >
                <i class="tmlps-i-edit-circle text-3xl h-8 w-8"></i>
              </button>

              <button
                type="button"
                class="rounded-full focus:outline-none focus:ring"
                @click="handlerDelete(admin)"
              >
                <i class="tmlps-i-delete-circle text-3xl h-8 w-8"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-start-4 col-span-6 mt-8 mx-auto">
          <img
            src="/img/search-no-results.png"
            alt="no-records"
            class="h-50 w-full object-cover md:w-80 mx-auto"
          />
          <h2 class="text-2xl text-center -mt-10">
            No hay administradores registrados por el momento
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSwitch from '@/components/BaseSwitch';
export default {
  name: 'admins-table',
  props: {
    admins: { type: Array, default: null },
  },
  components: { BaseSwitch },
  methods: {
    handlerDelete(admin) {
      this.$emit('delete-admin', admin);
    },
    handlerEdit(admin) {
      this.$emit('edit-admin', admin);
    },
    async handleSwitchOn(admin) {
      await this.$store.dispatch('admin/enableAdmin', admin.id);
      this.$emit('reload-records', true);
    },
    handleSwitchOff(admin) {
      this.$emit('disable-admin', admin);
    },
    handleSwitchInput() {},
  },
};
</script>
