<template>
  <div>
    <div class="flex items-center justify-between border-b pb-4">
      <h3 class="font-bold">{{ admin.admins.length }} administradores de Timelapse México</h3>
      <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
        Agregar
      </BaseButton>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap -m-4">
        <admins-table
          :admins="admin.admins"
          @disable-admin="handlerAdminDisableConfirmation"
          @edit-admin="handlerAdminEditModal"
          @delete-admin="handlerAdminDeleteConfirmation"
          @reload-records="reloadData"
        />
      </div>
    </div>

    <!-- CREATE ADMIN -->
    <admins-create-modal
      :show="showCreateModal"
      @close="showCreateModal = false"
      @created="reloadData"
    />

    <!-- DISABLE ADMIN -->
    <admins-disable-confirmation-modal
      :show="showAdminDisableConfirmation"
      :admin="current"
      @close="showAdminDisableConfirmation = false"
      @reload-records="reloadData"
    />

    <!-- EDIT ADMIN -->
    <admins-edit-modal
      :show="showEditModal"
      :admin="current"
      @close="showEditModal = false"
      @reload-records="reloadData"
    />

    <!-- DELETE ADMIN -->
    <admins-delete-confirmation-modal
      :show="showDeleteModal"
      :admin="current"
      @close="showDeleteModal = false"
      @reload-records="reloadData"
    />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import AdminsTable from './components/Table';
import AdminsEditModal from './components/EditModal';
import AdminsCreateModal from './components/CreateModal';
import AdminsDeleteConfirmationModal from './components/DeleteConfirmationModal';
import AdminsDisableConfirmationModal from './components/DisableConfirmationModal';
export default {
  name: 'admins-list',
  components: {
    BaseButton,
    AdminsTable,
    AdminsEditModal,
    AdminsCreateModal,
    AdminsDeleteConfirmationModal,
    AdminsDisableConfirmationModal,
  },
  data() {
    return {
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      showAdminDisableConfirmation: false,
      selected: null,
    };
  },
  computed: {
    ...mapState(['admin']),
    current() {
      return this.selected;
    },
  },
  methods: {
    async reloadData(reload) {
      if (reload) await store.dispatch('admin/fetchAdmins');
    },
    async handlerAdminEditModal(admin) {
      this.selected = admin;
      this.showEditModal = true;
    },
    handlerAdminDeleteConfirmation(admin) {
      this.selected = admin;
      this.showDeleteModal = true;
    },
    handlerAdminDisableConfirmation(admin) {
      this.selected = admin;
      this.showAdminDisableConfirmation = true;
    },
  },
  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('admin/fetchAdmins');
  next();
}
</script>
