<template>
  <BaseModal :show="show" v-if="admin" @close="handleCancelModal">
    <h3 slot="header" class="font-bold text-base text-left">Editar administrador</h3>

    <div class="space-y-6">
      <!-- Admin name -->
      <div>
        <BaseInput
          label="Nombre"
          placeholder="Nombre"
          :error="errors.first('name')"
          v-model="form.name"
        />
      </div>

      <!-- Admin email -->
      <div>
        <BaseInput
          label="Correo"
          placeholder="Correo"
          :error="errors.first('email')"
          :disabled="true"
          :readonly="true"
          v-model="form.email"
        />
      </div>
    </div>

    <div slot="footer" class="flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="handleCancelModal">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleUpdateAdmin"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >Actualizar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
export default {
  name: 'AdminEditModal',
  components: { BaseButton, BaseModal, BaseInput },
  props: {
    show: { type: Boolean, default: false },
    admin: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    form: {
      get() {
        return { ...this.admin };
      },
      set(value) {
        !value ? this.$emit('close') : this.$emit('set-admin', value);
      },
    },
  },
  methods: {
    handleCancelModal() {
      this.resetModal();
      this.$emit('close');
    },
    resetModal() {
      this.form = {};
      this.loading = false;
      this.errors.clear();
    },
    async handleUpdateAdmin() {
      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      this.loading = true;
      this.errors.clear();

      try {
        await this.$store.dispatch('admin/updateAdmin', {
          userId: this.admin.id,
          data: formData,
        });
        this.$emit('close');
        this.$emit('reload-records', true);
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
