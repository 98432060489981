<template>
  <BaseModal :show="show" @close="handleCancelConfirmation">
    <h3 slot="header" class="font-bold text-base">Eliminar administrador</h3>

    <p class="text-base">
      ¿Estás seguro que deseas eliminar al administrador
      <strong>
        {{ admin ? admin.name : null }}
      </strong>
      ? al hacerlo no podrá ingresar a la plataforma y se borrará su información.
    </p>

    <div slot="footer" class="mt-6 flex space-x-20">
      <BaseButton class="flex-grow py-3" secondary @click="handleCancelConfirmation">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleDeleteAdmin"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Eliminar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';
export default {
  name: 'admins-delete-confirmation-modal',
  components: { BaseButton, BaseModal },
  props: {
    show: { type: Boolean, default: false },
    admin: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleDeleteAdmin() {
      this.loading = true;

      try {
        await this.$store.dispatch('admin/deleteAdmin', this.admin.id);
        this.$emit('close');
        this.$emit('reload-records', true);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    handleCancelConfirmation() {
      this.$emit('close');
      this.$emit('cancel');
    },
  },
};
</script>
